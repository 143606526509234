
















import {
  onScroll
} from "@/utils/func";
import {
  Component,
  Vue
} from "vue-property-decorator";

import MomentItem from '@/components/moment/item.vue'
import {
  api
} from "@/api";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  TimelineObj
} from "@/utils/interface";

@Component({
  name: 'friend-moment',
  components: {
    MomentItem
  }
})
export default class FriendMoment extends Vue {
  private friendName = this.$route.query.nickname || ''
  private friendStrId = this.$route.query.wxStrId || ''
  private list: any = []
  private pageNo = 1
  private pageSize = 5
  private hasNextPage = true
  private snsTips: string = ''
  private isRequest = false

  private get wxId(): number {
    return WechatModule.wxId
  }

  private mounted() {
    this.isRequest = false
    this.getList()
  }

  /**
   * @func 监听滚动
   */
  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        if (this.list.length > 0) {
          this.getList()
        }
      }
    })
  }

  /**
   * @func 获取好友朋友圈
   */
  private async getList() {
    if (this.isRequest) return
    if (!this.hasNextPage) return
    this.isRequest = true
    try {
      const res: any = await api.getUserTimelineList({
        wechat_id: this.wxId,
        username: this.friendStrId,
        offset: (this.pageNo - 1) * this.pageSize,
        num: this.pageSize
      });
      this.snsTips = res.sns_ret_tips;
      !res.list || res.list.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++;
      if (res.list) {
        res.list.map((val: TimelineObj) => {
          let sns_like_and_comment = val.like_and_comment && JSON.parse(val.like_and_comment);
          val.like = sns_like_and_comment ? sns_like_and_comment.likeUserList : [];
          val.comment = sns_like_and_comment ? sns_like_and_comment.commentUserList : [];
          val.images = val.images && JSON.parse(val.images);
          val.pics = []
          if (val.images) {
            for (let i = 0; i < val.images.length; i++) {
              val.pics.push(val.images[i].imageUrl)
            }
          }
          val.wechat_id = this.wxId;
          val.media = val.media && JSON.parse(val.media);
          this.list.push(val);
        })
      }
      this.isRequest = false
    } catch (err) {
      console.log(err)
      this.isRequest = false
    }
  }
}
